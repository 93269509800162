import { format, parseISO } from "date-fns";
import i18n from "../plugins/i18n";

const localeMap = {
  en: "en-US",
  pl: "pl-PL",
};

export function formatDate(date) {
  const locale = i18n.global.locale.value;

  return date.toLocaleDateString(localeMap[locale], {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}

export const formatIsoDate = (value) => {
  const parsedDate = parseISO(value);
  return format(parsedDate, "dd-MM-yyyy");
};
