<script setup lang="ts">
import Wysiwyg from "./Wysiwyg.vue";
import Page from "@/components/Page.vue";
import Chat from "@/components/chat/Chat.vue";
import VariableInputs from "./VariableInputs.vue";
import BackButton from "@/components/BackButton.vue";
import Wizard from "@/components/documents/wizard/Wizard.vue";
import LegalAssistant from "@/components/mobile/LegalAssistant.vue";
import ShareHistoryDialog from "@/components/dialogs/ShareHistoryDialog.vue";
import WizardVariables from "@/components/documents/wizard/WizardVariables.vue";
import EditorHistoryModal from "@/components/ProseMirror/EditorHistoryModal.vue";
import EditDocumentNameDialog from "@/components/dialogs/EditDocumentNameDialog.vue";
import DocumentNavigator from "@/components/documents/navigator/DocumentNavigator.vue";
import SubscriptionOrSinglePaymentRequiredDialog from "@/components/dialogs/SubscriptionOrSinglePaymentRequiredDialog.vue";

import { useRoute, useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import axios from "axios";

import { applyStyleToVariable } from "@/utils/editorStyling";
import parseXMLToJSON from "@/utils/xmlParser/parseXMLToJSON";
import { useIsMobile } from "@/utils/checkMobile";
import useCookies from "@/utils/cookies";
import useGTM from "@/utils/gtm";

const route = useRoute();
const router = useRouter();
const store = useStore();
const cookies = useCookies();
const $t = useI18n().t;
const isMobile = useIsMobile();
const isWizardCompleted = ref(false);
const isWizardVariablesCompleted = ref(false);
const isAiAssistantOpen = ref(cookies.get("ai-assistant-open") === "1");
const isDocumentedLoaded = ref(false);
const isEditNameDialogOpen = ref(false);

const isPaymentDialogOpen = ref(false);
provide("isPaymentDialogOpen", isPaymentDialogOpen);

const wysiwygKey = ref(0);
const wysiwygEditable = computed(() => isWizardCompleted.value && isWizardVariablesCompleted.value);
const toast = useToast();
const docContainerREF = ref(null);
const docContainerHeight = computed(() => {
  const rect = docContainerREF.value?.getBoundingClientRect();
  return `${rect?.height}px` || "100%";
});
const isTemporaryUser = computed(() => store.state.auth.user.isTemporary);
const showHistory = ref(false);
const documentId: Ref<string> = ref(route.params.uuid as string);
const documentTitle = ref($t("creator.new_document"));
const documentMeta = ref();
const document = ref();
const documentName = computed(() => document.value?.name || $t("creator.new_document"));
const messages = ref([]);
const tab = ref("form");

const isFullScreenChat = ref(false);
const userHasSubscription = computed(() => store.state.auth.user.hasValidSubscription);

const requiresPayment = computed(
  () => !userHasSubscription.value && document.value && !document.value.is_paid,
);

const variableSelected = () => {
  tab.value = "form";
  isFullScreenChat.value = false;
};

const showEditDocumentNameDialog = () => {
  isEditNameDialogOpen.value = true;
};

const shouldOpenDocumentShareHistory = computed(
  () => router.currentRoute.value.name === "document-share-history",
);
const isShareHistoryDialogOpen = ref(false);

const parseVariables = (variables) => {
  const items = variables.map((variable) => {
    if (!variable.options) return variable;

    return {
      ...variable,
      options: variable.options.split(";"),
      max_options: variable.max_options,
      list_type: variable.list_type,
    };
  });

  return items || [];
};

const getWizardData = async () => {
  const response = await axios.get(`api/documents/${documentId.value}/meta`);
  documentMeta.value = response.data.data;

  const { are_variables_set } = documentMeta.value;
  const { is_completed, steps } = documentMeta.value.wizard;
  const { variables, title } = documentMeta.value;

  isWizardCompleted.value = is_completed;

  if (!is_completed) {
    isWizardVariablesCompleted.value = false;
  }

  const wizardAnswers = steps.reduce((answers, step) => {
    step.subSteps.forEach((subStep) => {
      subStep.questions.forEach((question) => {
        answers.push({
          stepId: step.id,
          questionId: question.id,
          answer: question.answer,
        });
      });
    });
    return answers;
  }, []);

  store.commit("editor/setWizardSteps", steps);
  store.commit("editor/setAvailableVariables", parseVariables(variables));
  store.commit("editor/updateDocumentTitle", title);
  store.commit("editor/setWizardAnswers", wizardAnswers);

  documentTitle.value = title;

  if (!steps.length && !is_completed) {
    await handleCompleteWizard();
  }
  if (is_completed) {
    store.commit("editor/completeWizard");
  }
  if (are_variables_set) {
    store.commit("editor/completeWizardVariables");
    isWizardVariablesCompleted.value = true;
  }
};

const updateDocument = async () => {
  if (documentId.value) {
    store.commit("editor/updateDocumentId", documentId.value);

    const response = await axios.get(`api/documents/${documentId.value}`);
    document.value = response.data.data;
    store.commit("editor/updateContent", document.value);
    store.commit("editor/switchContentChangeStatus", false);
    isDocumentedLoaded.value = true;
    wysiwygKey.value++;
  }

  if (documentMeta.value) {
    document.value.meta = documentMeta.value;
  }
};

const showPaymentDialog = () => {
  isPaymentDialogOpen.value = true;
};

const deleteDocument = async () => {
  await router.push({ name: "documents" });
};

const showHistoryModal = () => {
  showHistory.value = !showHistory.value;
};

const showShareHistoryDialog = () => {
  isShareHistoryDialogOpen.value = !isShareHistoryDialogOpen.value;
};

const saveHistory = async () => {
  const content = parseXMLToJSON(document.value.content);
  await axios.post(`api/documents/${documentId.value}/history`, {
    content: JSON.stringify(content),
    created_at: new Date().toISOString(),
  });
};

const legalChat = ref(null);

const handleNoAction = (stepId) => {
  if (legalChat.value) {
    legalChat.value.handleNoAction(stepId);
  }
};

const handleSaveEditableVariable = () => {
  saveHistory();
};

const handleCompleteWizard = async () => {
  await axios.patch(`api/documents/${documentId.value}/meta/complete`);
  await getWizardData();
  await updateDocument();
  await saveHistory();

  useGTM.pushEvent({
    event: "document_wizzard_finished",
    document_template: document.value.template_id,
  });
};

const handleCompleteVariablesWizard = async () => {
  await axios.patch(`api/documents/${documentId.value}/meta/variables/complete`);
  isWizardVariablesCompleted.value = true;

  store.commit("editor/completeWizardVariables");
  // We autosave the document for it to change status after completing both wizards
  // Daniel please do not remove before thinking it through

  const updatedContent = store.state.editor.content;
  const content = parseXMLToJSON(updatedContent);

  const documentResponse = await axios.post(`api/documents/${documentId.value}?_method=PUT`, {
    name: document.value?.name || "",
    content: JSON.stringify(content),
    template_id: document.value.template_id,
    directory_id: document.value.directory?.id,
  });

  document.value = documentResponse.data.data;

  await getWizardData();
  document.value.meta = documentMeta.value;

  toast.success($t("notifications.wizard_completed"), {
    timeout: 10000,
  });

  useGTM.pushEvent({
    event: "document_finished",
    document_template: document.value.template_id,
  });
};

const toggleAiAssistant = () => {
  const cookieName = "ai-assistant-open";
  const isAiOpen = cookies.get(cookieName) === "1";
  cookies.set(cookieName, isAiOpen ? "0" : "1");
  isAiAssistantOpen.value = !isAiOpen;
};

const handleClickOutside = (event, el) => {
  if (isWizardCompleted.value && isWizardVariablesCompleted.value) {
    store.commit("editor/updateEditableVariable", {
      name: "",
      value: "",
      description: "",
      type: "text",
      id: null,
    });
    applyStyleToVariable(null);
  }
};

const updateDocumentName = (name) => {
  document.value.name = name;
};

const toggleFullScreenChat = () => {
  isFullScreenChat.value = !isFullScreenChat.value;
};

onMounted(async () => {
  await getWizardData();
  await updateDocument();

  if (shouldOpenDocumentShareHistory.value) {
    showShareHistoryDialog();
  }
  store.commit("shared/updateIsLoading", false);
});

onUnmounted(() => {
  store.commit("editor/resetEditor");
});

watch(
  () => route.params.uuid,
  async () => {
    documentId.value = route.params.uuid;
    await getWizardData();
    await updateDocument();
  },
  { deep: true },
);

watch(
  () => isWizardVariablesCompleted.value,
  () => (isWizardCompleted.value === true ? (tab.value = "preview") : (tab.value = "form")),
);
</script>

<template>
  <!-- Mobile -->
  <div
    v-if="isDocumentedLoaded && isMobile"
    v-click-outside="handleClickOutside"
    ref="docContainerREF"
  >
    <Page
      class="creator-page"
      :title="$t('documents.new_document')"
      :with-spacing="false"
      :with-header-spacing="false"
    >
      <template #back-button>
        <BackButton
          :mobile="isMobile"
          :to="'/new-document'"
          margin-top="0"
          margin-bottom="0"
          class="mr-4"
        />
      </template>
      <template #default>
        <h3 class="document-name-mobile">
          {{ documentTitle }} - {{ documentName }}
          <DocumentNavigator
            :document="document"
            :has-wizard-completed="isWizardCompleted"
            :buttons="['file', 'share', 'ai']"
            @show-history-modal="showHistoryModal"
            @show-share-history-dialog="showShareHistoryDialog"
            @update-document="updateDocument"
            @deleted-document="deleteDocument"
            @toggle-ai-assistant="toggleAiAssistant"
          />
        </h3>

        <v-card>
          <v-tabs v-model="tab" class="tabs">
            <v-tab value="form">Form</v-tab>
            <v-tab value="preview">Preview</v-tab>
          </v-tabs>

          <v-card-text>
            <div v-show="tab === 'form'">
              <!-- Form -->
              <Wizard
                v-if="!isWizardCompleted"
                @complete-wizard="handleCompleteWizard"
                :isAiAssistantOpen="isAiAssistantOpen"
              />
              <WizardVariables
                v-else-if="!isWizardVariablesCompleted"
                @next="handleSaveEditableVariable"
                @complete-wizard="handleCompleteVariablesWizard"
                :key="wysiwygKey"
                :currentDocument="document"
                :meta="documentMeta"
                :requiresPayment="requiresPayment"
              />
              <VariableInputs
                v-else
                :isAiAssistantOpen="isAiAssistantOpen"
                :documentId="documentId"
                @save="handleSaveEditableVariable"
                @close-editor="tab = 'preview'"
              />
            </div>
            <!-- Preview -->
            <Wysiwyg
              v-if="document"
              v-show="tab == 'preview'"
              :document="document"
              :isWizardCompleted="isWizardCompleted"
              :editor-editable="wysiwygEditable"
              :key="wysiwygKey"
              @variable-clicked="variableSelected"
            />
          </v-card-text>
        </v-card>
      </template>
      <template #footer>
        <LegalAssistant :route="`/documents/${documentId}/chat`" />
      </template>
    </Page>
  </div>

  <!-- Desktop -->
  <Page
    v-if="isDocumentedLoaded && !isMobile"
    v-click-outside="handleClickOutside"
    :title="documentTitle + ' - ' + documentName"
    :with-spacing="false"
    :with-header-spacing="false"
    class="document-container"
  >
    <template #title-actions>
      <VBtn
        v-if="!isTemporaryUser"
        @click="showEditDocumentNameDialog"
        class="edit-button"
        variant="flat"
      >
        <VIcon>mdi-pencil</VIcon>
      </VBtn>
    </template>
    <template #default>
      <div ref="docContainerREF">
        <div class="navigator">
          <BackButton to="/new-document" margin-top="2" margin-bottom="2" />
          <DocumentNavigator
            :document="document"
            :has-wizard-completed="isWizardCompleted"
            :buttons="['file', 'share', 'ai']"
            @show-history-modal="showHistoryModal"
            @show-share-history-dialog="showShareHistoryDialog"
            @update-document="updateDocument"
            @deleted-document="deleteDocument"
            @toggleAiAssistant="toggleAiAssistant"
          />
        </div>
      </div>
      <div class="editor-container">
        <div class="editor">
          <Wysiwyg
            v-if="document"
            :document="document"
            :isWizardCompleted="isWizardCompleted"
            :editor-editable="wysiwygEditable"
            :key="wysiwygKey"
            @variable-clicked="variableSelected"
          />
        </div>
        <div class="helpers">
          <Wizard
            v-show="!isFullScreenChat"
            v-if="!isWizardCompleted"
            @complete-wizard="handleCompleteWizard"
            @no-action-taken="handleNoAction"
            :isAiAssistantOpen="isAiAssistantOpen"
          />
          <WizardVariables
            v-show="!isFullScreenChat"
            v-else-if="!isWizardVariablesCompleted"
            @next="handleSaveEditableVariable"
            @complete-wizard="handleCompleteVariablesWizard"
            @no-action-taken="handleNoAction"
            @paymentRequired="showPaymentDialog"
            :key="wysiwygKey"
            :currentDocument="document"
            :meta="documentMeta"
            :requiresPayment="requiresPayment"
          />
          <VariableInputs
            v-show="!isFullScreenChat"
            v-else
            :isAiAssistantOpen="isAiAssistantOpen"
            :documentId="documentId"
            @save="handleSaveEditableVariable"
          />

          <div class="chat" :class="isFullScreenChat ? 'h-100' : ''" v-if="isAiAssistantOpen">
            <Chat
              ref="legalChat"
              v-if="isAiAssistantOpen"
              :initial-messages="messages"
              :currentDocument="document"
              :isFullScreenChat="isFullScreenChat"
              :isResizeEnabled="!isWizardVariablesCompleted"
              @toggle-full-screen-chat="toggleFullScreenChat"
            />
          </div>
        </div>
      </div>
    </template>
  </Page>

  <!-- Shared components -->
  <div v-if="isWizardCompleted && showHistory">
    <EditorHistoryModal
      :documentId="documentId"
      :is-history-open="showHistory"
      @close="showHistoryModal"
    />
  </div>

  <EditDocumentNameDialog
    :is-dialog-open="isEditNameDialogOpen"
    :document="document"
    @close-dialog="isEditNameDialogOpen = false"
    @update-document-name="updateDocumentName"
  />

  <ShareHistoryDialog
    v-if="document"
    :document="document"
    :is-dialog-open="isShareHistoryDialogOpen"
    @close-dialog="isShareHistoryDialogOpen = false"
  />

  <SubscriptionOrSinglePaymentRequiredDialog
    v-if="(isWizardCompleted && requiresPayment) || isPaymentDialogOpen"
    :is-dialog-open="(isWizardCompleted && requiresPayment) || isPaymentDialogOpen"
    :show-close-button="true"
    :template-id="document.template_id"
    @close-dialog="
      () => {
        isPaymentDialogOpen = false;
      }
    "
  />
</template>

<style scoped lang="scss">
.creator-page {
  padding-right: 10px;
  padding-left: 10px;

  // @media (max-width: 768px) {
  //   padding-right: 0;
  //   padding-left: 0;
  // }
}

.document-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - var(--v-layout-top) - v-bind(docContainerHeight) + 30px);
  width: 100%;

  @media (min-width: 768px) {
    padding-bottom: 0 !important;
  }

  .document-container-header {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;

    h2 {
      border-image: linear-gradient(to left, var(--cambridge-blue), var(--booger-buster)) 30;
      border-width: 2px;
      border-style: solid;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      width: 100%;
    }

    .edit-button {
      text-decoration: none;
      color: #858788;
      font-size: 0.938rem;

      &:hover {
        color: black;
      }
    }

    .header-buttons-container {
      display: flex;
      justify-content: space-between;
      padding-bottom: 10px;
      gap: 10px;
    }
  }

  .editor-container {
    display: flex;
    height: 100%;
    width: 100%;
    overflow: hidden;

    .editor {
      width: 50%;
      min-width: 500px;
      max-width: 80vw;
      overflow: auto;
      resize: horizontal;
    }

    .helpers {
      width: 50%;
      display: flex;
      flex-direction: column;

      .variables {
        height: 50%;
      }

      .chat {
        flex: 1 1 auto;
        height: 50%;
      }

      .chat::-webkit-resizer {
        content: "";
        background: url("/images/icons/vertical-resize.svg") no-repeat center center;
        background-size: 80%;
        background-color: var(--light-grey);
        border: 1px solid var(--light-grey);
      }
    }
  }
}

.document-name {
  display: flex;
  justify-content: space-between;
  font-size: 1.25rem;
  font-weight: normal;
  margin-bottom: 10px;
}

.document-name-mobile {
  display: flex;
  font-size: 1.5rem;
  font-weight: normal;
  margin-bottom: 20px;
}

.tabs button {
  width: 50%;
}

.tabs .v-tab {
  background: var(--ghost-white) !important;
  color: var(--dark-grey);
  font-size: 1.125rem;
}

.tabs .v-tab--selected {
  background: var(--cambridge-blue) !important;
  color: white;
}

.navigator {
  display: flex;
  justify-content: space-between;
  margin-top: -10px;
  margin-bottom: 10px;
}

:deep(.v-field.v-field--active .v-label.v-field-label--floating) {
  white-space: normal !important;
}

:deep(.v-select .v-select__selection-text) {
  text-overflow: inherit;
  white-space: normal;
}
</style>
