<script setup>
import { defineProps, onMounted, ref, defineEmits } from "vue";
import axios from "axios";
import { useToast } from "vue-toastification";
import { useI18n } from "vue-i18n";

import SubscriptionInfoTable from "./subscription_info/SubscriptionInfoTable.vue";
import PlansModal from "./modal/plans/PlansModal.vue";
import PaymentHistoryModal from "./modal/payment_history/PaymentHistoryModal.vue";
import CancelPlanDialog from "./modal/CancelPlanDialog.vue";
import SubscriptionButton from "./SubscriptionButton.vue";
import CurrentPlanInfo from "./CurrentPlanInfo.vue";
import { useIsMobile } from "@/utils/checkMobile";

const isMobile = useIsMobile();
const { subscriptionData } = defineProps(["subscriptionData"]);
const emit = defineEmits(["getUserData"]);

const createSubscriptionModalOpen = ref(false);
const cancelPlanDialog = ref(false);
const listPaymentsDialog = ref(false);
const plans = ref([]);
const isLoaded = ref(false);
const subscription = ref(null);

const { t } = useI18n();

const getSubscriptionPlansData = async () => {
  try {
    const response = await axios.get("/api/subscription-plans");
    plans.value = response.data;
  } catch (error) {
    console.error(error);
  } finally {
    isLoaded.value = true;
  }
};

const cancelSubscription = async () => {
  await axios
    .post("/api/user/subscription/cancel")
    .then(() => {
      cancelPlanDialog.value = false;
      emit("getUserData");
      useToast().success(t("billing.subscription_cancelled"));
    })
    .catch((error) => {
      useToast().error(error.response.data.message);
    });
  await updateSubscriptionData();
};

const resumeSubscription = async () => {
  await axios
    .post("/api/user/subscription/resume")
    .then(() => {
      emit("getUserData");
      useToast().success(t("billing.subscription_resumed"));
    })
    .catch((error) => {
      useToast().error(error.response.data.message);
    });
  await updateSubscriptionData();
};

const updateSubscriptionData = async () => {
  await getSubscriptionPlansData();
  if (subscriptionData) {
    subscription.value = {
      plan: plans.value.find((plan) => plan.id === subscriptionData.stripe_price)?.name || "",
      from: subscriptionData.created_at,
      to: subscriptionData.ends_at,
      status: subscriptionData.stripe_status,
    };
  }
};

const handleInstantBuyClickedOnLandingPage = (createSubscriptionModalOpen) => {
  if (localStorage.registerFlowType === "subscribe") {
    createSubscriptionModalOpen.value = true;
    localStorage.removeItem("registerFlowType");
  }
};

onMounted(async () => {
  await updateSubscriptionData();
  handleInstantBuyClickedOnLandingPage(createSubscriptionModalOpen);
});
</script>
<template>
  <div class="box">
    <h4 v-if="!isMobile" class="header">{{ t("billing.subscription") }}</h4>

    <SubscriptionInfoTable
      :subscription="subscription"
      @showHistory="listPaymentsDialog = true"
      @cancelPlan="cancelPlanDialog = true"
      @resumeSubscription="resumeSubscription"
    />

    <SubscriptionButton
      v-if="!subscriptionData"
      variant="upgrade"
      @click="createSubscriptionModalOpen = true"
    />

    <CurrentPlanInfo :is-premium="subscriptionData" />

    <CancelPlanDialog
      :open="cancelPlanDialog"
      @close="cancelPlanDialog = false"
      @submit="cancelSubscription"
    />

    <PlansModal
      :open="createSubscriptionModalOpen"
      :plans="plans"
      @updateSubscriptionData="updateSubscriptionData()"
      @close="createSubscriptionModalOpen = false"
    />

    <PaymentHistoryModal :open="listPaymentsDialog" @close="listPaymentsDialog = false" />
  </div>
</template>
<style scoped lang="scss">
.box {
  max-width: 580px;
}
.header {
  margin-bottom: calc(30px + 24px);
}
</style>
