import store from "@/store/store";
import { applyStyleToVariable } from "@/utils/editorStyling";

const handleGoToStep = (id: string) => {
  const isWizardCompleted = store.state.editor.are_variables_set;
  const variables = store.state.editor.availableVariables;

  if (isWizardCompleted) {
    const selectedVariable = variables.find((v) => v.id === id);
    const { answer, description, name, type, example, max_options, list_type } = selectedVariable;

    let variable = {
      answer,
      description,
      id,
      name,
      type,
      example,
      value: answer,
    };

    if (type === "select") {
      const options = variable.answer.split("\n")[0].split(",") || [];

      variable = {
        ...variable,
        value: options,
        answer: options,
        options: options,
        max_options: max_options,
        list_type: list_type,
      };
    }

    store.commit("editor/updateEditableVariable", variable);

    applyStyleToVariable(id);
  } else {
    const variableIndex = variables.findIndex((v) => v.id === id);
    if (variableIndex !== -1) {
      store.commit("editor/setCurrentVariableIndex", variableIndex);
    }
  }
};

export default handleGoToStep;
