<script setup>
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import axios from "axios";

const store = useStore();
const { t } = useI18n();

const isLoading = ref(false);
const isOpen = computed(() => !store.state.auth.user.hasTermsAccepted);

const termsCheckbox = ref(false);
const marketingCheckbox = ref(false);

const buttonDisabled = computed(() => !termsCheckbox.value);

const updateTermsAcceptanceOnServer = async () => {
  try {
    await axios.post("api/user/accept-consents", {
      terms_consent: termsCheckbox.value,
      marketing_consent: marketingCheckbox.value,
    });
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

const setTermsAcceptance = async () => {
  isLoading.value = true;

  const success = await updateTermsAcceptanceOnServer();
  success && store.commit("auth/setTermsAcceptance");

  isLoading.value = false;
};
</script>

<template v-if="!!store.state.auth.user">
  <v-dialog v-model="isOpen" persistent max-width="600">
    <v-card>
      <v-card-title class="dialog-title">
        {{ t("term_popup.title") }}
      </v-card-title>

      <v-card-text class="dialog-content">
        <!-- CHECKBOX TERMS -->
        <v-checkbox class="checkbox" label="Checkbox" v-model="termsCheckbox">
          <template v-slot:label>
            <p
              class="checkbox-label"
              v-html="
                t('term_popup.description.full', {
                  terms: t('term_popup.terms_and_conditions.title'),
                  termsLink: t('term_popup.terms_and_conditions.link'),
                  privacy: t('term_popup.privacy_policy.title'),
                  privacyLink: t('term_popup.privacy_policy.link'),
                })
              "
            ></p>
          </template>
        </v-checkbox>

        <!-- CHECKBOX MARKETING -->
        <v-checkbox class="checkbox" label="Checkbox" v-model="marketingCheckbox">
          <template v-slot:label>
            <p class="checkbox-label">{{ t("marketing_consent") }}</p>
          </template>
        </v-checkbox>

        <v-btn
          class="mt-5"
          color="primary"
          :text="t('term_popup.button')"
          variant="flat"
          @click="setTermsAcceptance"
          :loading="isLoading"
          :disabled="buttonDisabled"
        ></v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss">
.dialog-title {
  font-size: 1.5rem;
  font-weight: bold;
  padding: 23px 23px 0px;
  text-align: center;
}

.dialog-content {
  color: #858788;
  text-align: center;
}

.checkbox-label {
  text-align: left;
}

.close-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-right: 20px;

  img {
    cursor: pointer;
  }
}
</style>
