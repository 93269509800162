<script setup>
import axios from "axios";
import MenuLink from "@/components/mobile/MenuLink.vue";
import router from "@/router";
import {useI18n} from "vue-i18n";

const pages = ref([]);

const { t } = useI18n();

const getStaticPages = async () => {
  const response = await axios.get(`api/static-pages`);
  pages.value = response.data.data;
};

getStaticPages();
</script>

<template>
  <div v-for="page in pages" :key="page.id">
    <MenuLink :title="page.name" :page="page.type"/>
  </div>
  <MenuLink :title="t('common.need_help')" page="contact"/>
</template>

<style scoped lang="scss"></style>
