<script setup>
import { useToast } from "vue-toastification";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import axios from "axios";
import setUserData from "@/utils/setUserData";

const router = useRouter();
const { t } = useI18n();

const changeRoute = () => {
  const method = localStorage.registerFlowType;

  switch (method) {
    case "buy":
      localStorage.removeItem("registerFlowType");
      router.replace("/profile");
      break;
    case "subscribe":
      router.replace("/profile");
      break;
    default:
      router.replace("/new-document");
  }
};

const sendVerificationEmail = async () => {
  let url = router.currentRoute.value.fullPath;

  try {
    const response = await axios.get(`/api/user` + url);
    await setUserData();
    useToast().success(response.data.success);
    changeRoute();
  } catch (error) {
    useToast().error(t("notifications.default_error"));
    console.error(error);
  }
};

onMounted(() => {
  sendVerificationEmail();
});
</script>
