<script setup>
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useToast } from "vue-toastification";
import { applyStyleToVariable } from "@/utils/editorStyling";
import axios from "axios";
import scrollToElement from "@/utils/scrollToElement";
import { useIsMobile } from "@/utils/checkMobile";
import { formatIsoDate } from "@/utils/formatDate";

const { currentDocument, meta, requiresPayment } = defineProps([
  "currentDocument",
  "meta",
  "requiresPayment",
]);

const emit = defineEmits(["completeWizard", "next", "noActionTaken", "paymentRequired"]);
const store = useStore();
const { t } = useI18n();
const isMobile = useIsMobile();

const isLoading = ref(false);
const errorMessage = ref("");
const variablesNodes = [];
const allVariables = reactive(store.state.editor.availableVariables);
const availableVariables = ref([]);
const currentVariableIndex = computed(
  () => store.state.editor.variables_wizard.currentVariableIndex,
);
const currentVariable = computed(() => {
  errorMessage.value = "";
  return availableVariables.value[currentVariableIndex.value];
});

const progress = computed(
  () => ((currentVariableIndex.value + 1) / availableVariables.value.length) * 100,
);

const setVariablesFromApi = async () => {
  const preparedVariables = meta.variables.map((variable) => ({
    id: variable.id,
    value: variable.answer,
  }));

  store.commit("editor/setVariables", preparedVariables);
  store.commit(
    "editor/setCurrentVariableIndex",
    preparedVariables.filter((variable) => variable.value).length || 0,
  );
};

const variableValue = computed({
  get() {
    const { answer } = store.state.editor.editableVariable;

    if (currentVariable.value?.type === "date" && answer) {
      const year = answer.split("-")[0];
      const isIsoFormat = year.length === 4;

      if (isIsoFormat) {
        return answer;
      } else {
        return answer.split("-").reverse().join("-");
      }
    } else {
      return store.state.editor.editableVariable.answer;
    }
  },
  set(value) {
    const { id, name, type, description, options, max_options, list_type } = currentVariable.value;

    const newVariable = {
      id,
      name,
      type,
      description,
      answer: value,
      value: value,
    };

    if (type === "select") {
      const newValue = Array.isArray(value) ? [...value] : [value];
      const selectedOptions = options.filter((option) => newValue.includes(option));

      newVariable.value = selectedOptions;
      newVariable.answer = selectedOptions;
      newVariable.options = selectedOptions;
      newVariable.max_options = max_options;
      newVariable.list_type = list_type;
    }

    store.commit("editor/updateEditableVariable", newVariable);
    errorMessage.value = "";
  },
});

const handleSelectVariable = () => {
  const isLastItem = currentVariableIndex.value >= availableVariables.value.length;

  if (isLastItem && availableVariables.value.length > 0) {
    applyStyleToVariable(null);
    store.commit("editor/updateEditableVariable", {
      name: "",
      value: "",
      answer: "",
      description: "",
      type: "text",
      id: null,
    });

    emit("completeWizard");
    return;
  }

  const { id, name, type, description, answer } = currentVariable.value || {};

  applyStyleToVariable(id);
  store.commit("editor/updateEditableVariable", {
    id,
    name,
    type,
    description,
    value: store.state.editor.variables[id] || name,
    answer: store.state.editor.variables[id],
  });

  const selectedVariable = variablesNodes.find((variable) => variable.dataset.id === id);

  scrollToElement(selectedVariable);
};

const handleSaveVariable = async () => {
  if (requiresPayment) {
    emit("paymentRequired");
    return;
  }

  if (isLoading.value) return;

  if (currentVariable.value.type === "select") {
    if (!variableValue.value?.length) {
      errorMessage.value = t("errors.wizard.min_options");
      return;
    }

    if (currentVariable.value?.max_options < variableValue.value.length) {
      errorMessage.value = `${t("errors.wizard.max_options1")} ${currentVariable.value?.max_options} ${t("errors.wizard.max_options2")}`;
      return;
    }
  }

  if (!variableValue.value) {
    errorMessage.value = t("errors.editor.variable_value_required");
    return;
  }

  isLoading.value = true;
  const { id, type } = currentVariable.value;
  let newVariableValue = variableValue.value;

  if (type === "date") {
    newVariableValue = formatIsoDate(newVariableValue);
  }

  store.commit("editor/updateVariable", {
    id,
    value: newVariableValue,
    answer: newVariableValue,
  });

  try {
    await axios.patch(`api/documents/${currentDocument.id}/meta/variables/answer`, {
      identifier: id,
      answer: newVariableValue + "",
    });

    store.commit("editor/setCurrentVariableIndex", currentVariableIndex.value + 1);
    handleSelectVariable();
  } catch (error) {
    useToast().error(t("errors.global.too_many_requests"));
  } finally {
    isLoading.value = false;
  }
};

const handleBack = () => {
  store.commit("editor/setCurrentVariableIndex", currentVariableIndex.value - 1);
  handleSelectVariable();
};

const loadAvailableVariablesFromNodes = () => {
  variablesNodes.push(...Array.from(document.querySelectorAll("variable")));
  const variablesIds = variablesNodes.map((node) => {
    return node.dataset.id;
  });

  availableVariables.value = allVariables.filter((variable) => variablesIds.includes(variable.id));
};

//check if variables are loaded in the editor
const observeVariables = (callback) => {
  const observer = new MutationObserver((mutations) => {
    if (mutations.length) {
      callback();
      observer.disconnect();
    }
  });
  const config = {
    childList: true,
    subtree: true,
  };
  const targetNode = document.querySelector("#editor");
  if (targetNode) {
    observer.observe(targetNode, config);
  }
};

watch(currentVariable, (newValue) => applyStyleToVariable(newValue?.id), {
  deep: true,
});

const actionTaken = ref(false);
let actionTimer = null;

watch(
  () => currentVariable.value,
  () => {
    actionTaken.value = false;
    clearTimeout(actionTimer);
    actionTimer = setTimeout(() => {
      if (!actionTaken.value) {
        emit("noActionTaken", {
          id: currentVariable.value?.id,
          type: "variable",
        });
      }
    }, 60000); // 1 minute
  },
);

onMounted(() => {
  loadAvailableVariablesFromNodes();
  handleSelectVariable();
  setVariablesFromApi();

  observeVariables(() => {
    loadAvailableVariablesFromNodes();
    handleSelectVariable();
  });

  store.commit("editor/setWizardCurrentStep", null);
});
</script>

<template>
  <div class="variable-inputs-container">
    <!-- Mobile view -->
    <template v-if="isMobile">
      <div class="progress-bar">
        <span>
          {{ t("wizard.progress") }}
        </span>
      </div>

      <!-- Progress line -->
      <div class="wizard-buttons">
        <v-btn
          :disabled="currentVariableIndex <= 0"
          @click="handleBack"
          class="back-button"
          variant="plain"
        >
          <img width="7" src="/src/assets/icons/arrow-back.svg" class="mr-2" alt="Back" />
        </v-btn>
        <v-progress-linear
          v-model="progress"
          :height="18"
          color="#D3F073"
          bg-color="#F2F2F2"
          bg-opacity="1"
        ></v-progress-linear>
        <v-btn
          :disabled="currentVariableIndex >= availableVariables?.length - 1 || isLoading"
          @click="handleSaveVariable"
          class="forward-button"
          variant="plain"
          :loading="isLoading"
        >
          <img width="7" src="/src/assets/icons/arrow-back.svg" class="ml-2" alt="Forward" />
        </v-btn>
      </div>

      <!-- CONTENT -->
      <h3 v-if="currentVariable?.name !== ''">
        {{ currentVariable?.name }}
      </h3>

      <v-card v-if="currentVariable">
        <span class="input-label" v-if="currentVariable?.description">
          {{ currentVariable?.description }}
        </span>
        <v-text-field
          v-if="currentVariable?.type !== 'select'"
          v-model.trim="variableValue"
          :placeholder="currentVariable?.example"
          variant="outlined"
          :type="currentVariable?.type"
          @focus="currentVariable?.name === variableValue ? (variableValue = '') : null"
          :error-messages="errorMessage"
          @keydown.enter="handleSaveVariable"
          :disabled="isLoading || requiresPayment"
        ></v-text-field>

        <div class="options-fields" v-if="currentVariable?.type === 'select'">
          <v-checkbox
            v-for="option in currentVariable?.options"
            v-model="variableValue"
            :label="option"
            :value="option"
            hide-details
            density="compact"
          ></v-checkbox>
        </div>
        <p v-if="currentVariable?.type === 'select' && errorMessage" class="max-options-label">
          {{ errorMessage }}
        </p>
      </v-card>

      <v-btn
        :disabled="currentVariableIndex >= availableVariables?.length || isLoading"
        @click="handleSaveVariable"
        class="mobile-save-button"
        variant="flat"
        :loading="isLoading"
      >
        {{ t("common.save") }}
      </v-btn>
    </template>

    <!-- Desktop view -->
    <template v-else>
      <div class="progress-bar">
        <span>
          {{ t("wizard.progress") }}
        </span>
        <v-progress-linear
          v-model="progress"
          :height="10"
          color="#D3F073"
          bg-color="#F2F2F2"
          bg-opacity="1"
        ></v-progress-linear>
      </div>

      <h3 v-if="currentVariable?.name !== ''">
        {{ currentVariable?.name }}
      </h3>

      <v-card v-if="currentVariable" class="overflow-y-auto">
        <span class="input-label" v-if="currentVariable?.description">
          {{ currentVariable?.description }}
        </span>
        <v-text-field
          v-if="currentVariable?.type !== 'select'"
          v-model.trim="variableValue"
          :placeholder="currentVariable?.example"
          variant="outlined"
          :type="currentVariable?.type"
          @focus="currentVariable?.name === variableValue ? (variableValue = '') : null"
          :error-messages="errorMessage"
          @keydown.enter="handleSaveVariable"
          :disabled="isLoading || requiresPayment"
        ></v-text-field>

        <div class="options-fields" v-if="currentVariable?.type === 'select'">
          <v-checkbox
            v-for="option in currentVariable?.options"
            v-model="variableValue"
            :label="option"
            :value="option"
            hide-details
            density="compact"
          ></v-checkbox>
        </div>
        <p v-if="currentVariable?.type === 'select' && errorMessage" class="max-options-label">
          {{ errorMessage }}
        </p>
      </v-card>

      <div class="wizard-buttons">
        <v-btn
          v-if="currentVariableIndex > 0"
          @click="handleBack"
          class="back-button"
          variant="flat"
        >
          <img width="7" src="/src/assets/icons/arrow-back.svg" class="mr-2" alt="Back" />
          {{ t("common.back") }}
        </v-btn>
        <v-btn
          v-if="currentVariableIndex < availableVariables.length - 1"
          @click="handleSaveVariable"
          class="forward-button"
          variant="flat"
          :disabled="isLoading"
          :loading="isLoading"
        >
          {{ t("common.forward") }}
          <img width="7" src="/src/assets/icons/arrow-back-white.svg" class="ml-2" alt="Forward" />
        </v-btn>
        <v-btn
          v-if="currentVariableIndex === availableVariables.length - 1"
          @click="handleSaveVariable"
          class="forward-button"
          variant="flat"
          :disabled="isLoading"
          :loading="isLoading"
        >
          {{ t("common.save") }}
        </v-btn>
      </div>
    </template>
  </div>
</template>

<style scoped lang="scss">
.max-options-label {
  font-size: 0.8rem;
  color: var(--eerie-black);
  margin-top: 10px;
  color: rgb(176, 0, 32);
}

:deep(.v-card-item) {
  padding: 0;
  margin-top: 20px;
}
:deep(.v-card-text) {
  padding: 0;
  margin-top: 5px;
}
.v-card {
  border: none;
}
.variable-inputs-container {
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  min-height: 50%;
  padding: 10px;
  transition: 0.3s ease-in-out;
  overflow: hidden;

  @media (min-width: 768px) {
    padding: 10px 20px;
    display: flex;
    gap: 16px;
  }

  .input-label {
    font-size: 0.875rem;
    color: var(--eerie-black);
  }

  :is(h3) {
    font-size: 0.875rem;
    font-weight: 600;
  }

  .progress-bar {
    display: block;
    justify-content: space-evenly;
    align-items: center;

    @media (min-width: 768px) {
      display: flex;
    }

    span {
      font-size: 0.688rem;
      color: var(--eerie-black);
      margin-right: 20px;
    }
  }

  .wizard-buttons {
    display: flex;
    justify-content: end;
    gap: 16px;

    @media (max-width: 768px) {
      gap: 8px;
      margin-bottom: 42px;
      margin-left: -5px;
      margin-right: -5px;
    }

    .forward-button {
      background-color: var(--blue-bonnet) !important;
      color: white !important;
    }

    .forward-button:disabled {
      color: var(--dark-grey) !important;
      background-color: white !important;

      img {
        opacity: 0.5;
      }

      :deep(.v-btn__overlay) {
        background-color: white !important;
      }
    }

    .forward-button,
    .back-button {
      text-decoration: none;
      color: #858788;
      font-size: 0.8rem;

      @media (max-width: 768px) {
        margin-top: 2px;
        min-width: 14px !important;
        max-width: 14px !important;
        min-height: 14px !important;
        max-height: 12px !important;
        padding: 0 !important;
      }

      img {
        width: 5px;
      }

      &:hover {
        color: black;
      }
    }

    .forward-button {
      img {
        transform: rotate(180deg);
      }
    }
  }
}

.mobile-save-button {
  color: white;
  background: var(--cambridge-blue);
  margin: 25px 50px 50px;
  font-size: 1.125rem;
}
</style>
